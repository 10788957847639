import React from 'react';
import intlogo from '../../assets/int.png'; 
import fobalogo from '../../assets/academy-logo.png'; 
import fobdlogo from '../../assets/fobd-logo.png'; 
import './footer.css';

const Footer = () => (
 


  <div className="FOBM__footer section__padding">
     <div className="footer-container">
    <div className="FOBM__footer-heading">
      
    </div>

    

    <div className="FOBM__footer-links">
      <div className="FOBM__footer-links_logo">
        
 
      </div>

    

      <div className="FOBM__footer-links_div">
        <h4>Full of Beans Media</h4>
        <hr></hr>
        <p><a href="privacy-policy.html" target="_blank">Privacy Policy</a></p>
        <p><a href="cookie-policy.html" target="_blank">Cookie Policy</a></p>

     
      </div>

      <div className="FOBM__footer-links_div">
        <h4>Services</h4>
        <hr></hr>
        <p><a href="web-development.html" target="_blank">Web Development</a></p>
        <p><a href="google-ads.html" target="_blank">Google Ads</a></p>
        <p><a href="bing-ads.html" target="_blank">Bing Ads</a></p>
        <p><a href="facebook-ads.html" target="_blank">Facebook Ads</a></p>
        <p><a href="seo.html" target="_blank">SEO</a></p>
        <p><a href="linkedin-ads.html" target="_blank">Linkedin Ads</a></p>
      </div>

      <div className="FOBM__footer-links_div">
    <h4>Full of Beans Academy</h4>
    <hr></hr>
    <p><a href="https://fullofbeansmedia.com/academy/" target="_blank">Home</a></p>
    <p><a href="https://fullofbeansmedia.com/academy/free-lessons.html" target="_blank">Free Lessons</a></p>
    <p><a href="https://fullofbeansmedia.com/academy/free-courses.html" target="_blank">Free Courses</a></p>
    <p><a href="https://fullofbeansmedia.com/academy/articles.html" target="_blank">Articles</a></p>
    <p><a href="https://fullofbeansmedia.com/academy/resources.html" target="_blank">Resources</a></p>
    <p><a href="https://fullofbeansmedia.com/academy/courses.html" target="_blank">Paid Courses</a></p>
</div>



      <div className="FOBM__footer-links_div">
        <h4>Logins</h4>
        <hr></hr>
        <p>Dashboard Login</p>
        <p>Academy Login</p>


      
      </div>
    </div>

    <div className="FOBM__footer-copyright">
      <p>@2023 Full of Beans Media. All rights reserved.</p>
      <p>Full of Beans Media is a brand by <b>Int Solutons ApS</b></p>
      <p>Company Number: DK-44145049</p>

      <div className="link-logos">
      <a href="https://int-recruit.com/" target="_blank">
      <img id="int-logo" src={intlogo} alt="Int Logo"></img>
    </a>
    <a href="https://fullofbeansmedia.com/academy/" target="_blank">
      <img id="int-logo" src={fobalogo} alt="Foba Logo"></img>
    </a>
    <a href="https://fullofbeansmedia.com/data/" target="_blank">
      <img id="int-logo" src={fobdlogo} alt="Fobd Logo"></img>
    </a>
      </div>
    </div>
    
  </div>
  </div>
);

export default Footer;
